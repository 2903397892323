/**
 * jQuery wordSwitchLoader 20180927
 *
 * @author Kiril Reznik
 * @license MIT/GPL
 */
(function($, window) {
  $.fn.wordSwitchLoader = function(userOptions) {
    /*
     Options
     */
    const $container = $(this);
    const $items = $container.children();
    const itemsCount = $items.length;
    const options = $.extend(
        {
          randomItem: true,
          timeout: 225
        },
        userOptions || {},
        $container.data());
    let timeout = options.timeout;
    let timeoutID = 0;

    /*
     Event handler: load
     */
    $(window).on('load', function() {
      // hide loader animation
      $container.fadeOut(timeout * 3, 'linear');
    });

    /*
     Event handler: beforeunload
     */
    $(window).on('beforeunload', function() {
      // show loader animation
      $container.fadeIn(timeout, 'linear');
    });

    // item index
    let i = 0;

    /*
     Random first item functionality
     */
    if (options.randomItem) {
      i = Math.floor(Math.random() * $items.length);
    }

    /*
     Items
     */
    $items.hide().eq(i).show();

    /**
     * Transition
     */
    function transition() {
      // clear timeout before we do anything to avoid orphan timeouts
      clearTimeout(timeoutID);

      // animate only elements that are visible
      if ($container.is(':visible')) {
        // set direction, current and next item
        const next = (i === (itemsCount - 1)) ? (0) : (i + 1);
        const $itemNext = $($items[next]);
        const $itemCurrent = $($items[i]);

        // set next item index
        i = next;

        // make sure that we are not switching items with the same index
        if ($itemCurrent[0] !== $itemNext[0]) {
          $itemCurrent.hide();
          $itemNext.show();
          setTimeout(function() {
            timeoutID = setTimeout(transition, timeout);
          }, timeout);

          // finish
          return;
        }
      } // if ($container.is(':visible'))

      // container not visible or switching items with the same index
      timeoutID = setTimeout(transition, timeout);
    }

    /*
     Initialize
     */
    timeoutID = setTimeout(transition, timeout);
  };
})(jQuery, window);
